






















































































































































































































































































































































































/* eslint-disable */
import { Vue, Component, Watch } from "vue-property-decorator";
import { More } from "@/mixin/more";
import { OtherModule } from "@/store/other";
import { api_user, user_chapter_right } from "@/api";
import Bread from "@/components/bread/index.vue";
import { numFormat, dateToKakao, dateToLast, toFixed0 } from "@/util/string";
import { Encryption } from "@/util/encryption";
@Component({
  components: {
    Bread,
  },
})
export default class extends More(api_user.get_chapter_left, false) {
  numFormat = numFormat;
  dateToKakao = dateToKakao;
  dateToLast = dateToLast;
  toFixed0 =toFixed0;
  step = false;
  index = 0;
  new_list = [
    {
      to: `/user/course`,
      title: "수강과목",
    },
    {
      title: "",
    },
  ];
  filter = {
    subject:this.id
  }
  course_info = {
    end_time: "",
    last_chapter: {
      exam_count: 0,
      solved_count: 0,
      correct_count: 0,
      solved_rate: 0,
      correct_rate: 0,
    },
    correct: [{
      solved_count: 0,
      correct_count: 0,
      correct_rate: 0,
    },{
      solved_count: 0,
      correct_count: 0,
      correct_rate: 0,
    },{
      solved_count: 0,
      correct_count: 0,
      correct_rate: 0,
    }],
    correct_rate:0,
    progress:[
      {
        exam_count:0,
        solved_count:0,
        solved_rate:0,
      },
      {
        exam_count:0,
        solved_count:0,
        solved_rate:0,
      },
      {
        exam_count:0,
        solved_count:0,
        solved_rate:0,
      }
    ],
    progress_rate:0,
    last: {
      id: 0,
      chapter_id: 0,
      level: 0,
    },
    subject: {
      title: "",
    },
    position: {
      title: "",
    },
  };
  show_bottom = false;
  get is_mobile() {
    return OtherModule.is_mobile;
  }
  get id() {
    return Number(Encryption.base_dec(this.$route.params.id));
  }
  to_last() {
    this.$router.push(
      `/user/course/${Encryption.base_enc(this.id)}/exam/${Encryption.base_enc(
        this.course_info.last.chapter_id
      )}/?level=${this.course_info.last.level}&exam_id=${
        this.course_info.last.id
      }`
    );
  }
  to_exam(level: number) {
    let last:any = this.list[this.index].chapter_list[level-1].last
    if(last?.id){
      this.$router.push(
          `/user/course/${Encryption.base_enc(this.id)}/exam/${Encryption.base_enc(
            last.chapter_id
          )}/?level=${last.level}&exam_id=${
            last.id
          }`
        );
      // this.$confirm('have history',{
      //   confirmButtonText:'go to history',
      //   cancelButtonText:'clear history'
      // }).then(()=>{
      //   this.$router.push(
      //     `/user/course/${Encryption.base_enc(this.id)}/exam/${Encryption.base_enc(
      //       last.chapter_id
      //     )}/?level=${last.level}&exam_id=${
      //       last.id
      //     }`
      //   );
      // }).catch(async()=>{
      //   await api_user.answer_finish({
      //     chapter: last.chapter_id,
      //     level: last.level,
      //     course_id: this.id
      //   });
      //   this.$router.push(
      //     `/user/course/${Encryption.base_enc(this.id)}/exam/${Encryption.base_enc(
      //       this.list[this.index].id
      //     )}/?level=${level}`
      //   );
      // })
    }else{
      this.$router.push(
        `/user/course/${Encryption.base_enc(this.id)}/exam/${Encryption.base_enc(
          this.list[this.index].id
        )}/?level=${level}`
      );
    }
  }
  async get_chapter_right() {
    const res: any = await api_user.get_chapter_right(this.id);
    this.course_info = res;
    this.new_list[1].title = res.subject.title;
  }
  show_step(index:number) {
    this.index = index;
    this.step = true;
  }
  @Watch("$route", { immediate: true })
  watch_route(res: any) {
    this.get_chapter_right();
    this.clear_list();
  }
}
